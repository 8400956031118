<template>
    <div>
        <custom-table
            name="snapchat-advertising"
            min-width="1200px"
            :items="items"
            :fields="fields"
            :rows-per-page="50"
            :header-margin="145"
            notification=""
            notification-type=""
            initial-sorting="Dealer ID"
            initial-sort-direction="desc"
            :footer-titles-colspan="3"
            has-totals
            slim-cells />
    </div>
</template>

<script>
import CustomTable from '@/components/globals/CustomTable';

export default {
    components: {
        CustomTable
    },
    props: {
        items: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            fields: [
                {
                    id: 'dealer_id',
                    header: 'Dealer ID',
                    value: 'dealer_id',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'dealer_name',
                    header: 'Dealer Name',
                    value: 'dealer_name',
                    align: 'left',
                    width: '200px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'ad_account_id',
                    header: 'Ad Account Id',
                    value: 'ad_account_id',
                    align: 'left',
                    width: '300px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'spend',
                    header: 'Spend',
                    value: 'metrics.spend.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.spend'),
                    total: true,
                    average: true,
                    format: 'currency',
                },
                {
                    id: 'impressions',
                    header: 'Impressions',
                    value: 'metrics.impressions.formatted',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.impressions'),
                    total: true,
                    average: true,
                    format: 'number',
                },
                {
                    id: 'cpm',
                    header: 'CPM',
                    value: 'metrics.cpm.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.cpm'),
                    total: true,
                    average: true,
                    format: 'currency',
                    calculated: (totals) => {
                        return totals.spend / ( totals.impressions / 1000 );
                    },
                    calculatedToolTip: {
                        title: 'CPM (Cost per 1,000 Impressions)',
                        details: [
                            {
                                item: 'spend',
                                value: null,
                            },
                            {
                                item: 'impressions',
                                value: null,
                            },
                            {
                                item: '1000',
                                value: 1000,
                            },
                        ]
                    }
                },
                {
                    id: 'swipes',
                    header: 'SU',
                    value: 'metrics.swipes.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.swipe_ups'),
                    total: true,
                    average: true,
                    format: 'number',
                },
                {
                    id: 'swipes_rate',
                    header: 'SUR',
                    value: 'metrics.swipes_rate.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.swipe_up_rate'),
                    total: true,
                    average: true,
                    format: 'percent',
                    calculated: (totals) => {
                        return totals.swipes / totals.impressions;
                    },
                    calculatedToolTip: {
                        title: 'SUR (Swipes Rate)',
                        details: [
                            {
                                item: null,
                                value: null,
                            },
                            {
                                item: 'swipes',
                                value: null,
                            },
                            {
                                item: 'impressions',
                                value: null,
                            },
                        ]
                    }
                },
                {
                    id: 'swipes_cost_per',
                    header: 'CPSU',
                    value: 'metrics.swipes_cost_per.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.cpsu'),
                    total: true,
                    average: true,
                    format: 'currency',
                    calculated: (totals) => {
                        return totals.spend / totals.swipes;
                    },
                    calculatedToolTip: {
                        title: 'CPSU (Cost Per Swipes)',
                        details: [
                            {
                                item: null,
                                value: null,
                            },
                            {
                                item: 'spend',
                                value: null,
                            },
                            {
                                item: 'swipes',
                                value: null,
                            },
                        ]
                    }
                },
                {
                    id: 'conversion_sign_ups',
                    header: 'Leads',
                    value: 'metrics.conversion_sign_ups.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.web_leads'),
                    total: true,
                    average: true,
                    format: 'number',
                },
                {
                    id: 'conversion_sign_ups_rate',
                    header: 'CR',
                    value: 'metrics.conversion_sign_ups_rate.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.lead_rate'),
                    total: true,
                    average: true,
                    format: 'percent',
                    calculated: (totals) => {
                        return totals.conversion_sign_ups / totals.swipes;
                    },
                    calculatedToolTip: {
                        title: 'CR (Conversion Sign ups Rate)',
                        details: [
                            {
                                item: null,
                                value: null,
                            },
                            {
                                item: 'conversion_sign_ups',
                                value: null,
                            },
                            {
                                item: 'swipes',
                                value: null,
                            },
                        ]
                    }
                },
                {
                    id: 'conversion_sign_ups_cost_per',
                    header: 'CPL',
                    value: 'metrics.conversion_sign_ups_cost_per.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.cpl'),
                    total: true,
                    average: true,
                    format: 'currency',
                    calculated: (totals) => {
                        return totals.spend / totals.conversion_sign_ups;
                    },
                    calculatedToolTip: {
                        title: 'CPL (Cost Per Lead)',
                        details: [
                            {
                                item: null,
                                value: null,
                            },
                            {
                                item: 'spend',
                                value: null,
                            },
                            {
                                item: 'conversion_sign_ups',
                                value: null,
                            },
                        ]
                    }
                },
                {
                    id: 'sales',
                    header: 'Sales',
                    value: 'metrics.conversion_purchases.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.sales'),
                    total: true,
                    average: true,
                    format: 'number',
                },
                {
                    id: 'cps',
                    header: 'CPS',
                    value: 'metrics.cps.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.cps'),
                    total: true,
                    average: true,
                    format: 'currency',
                    calculated: (totals) => {
                        return totals.spend / totals.sales;
                    },
                    calculatedToolTip: {
                        title: 'CPS (Cost Per Sale)',
                        details: [
                            {
                                item: null,
                                value: null,
                            },
                            {
                                item: 'spend',
                                value: null,
                            },
                            {
                                item: 'sales',
                                value: null,
                            },
                        ]
                    }
                }
            ]
        };
    }
};
</script>
