<template>
    <div>
        <custom-table
            name="tiktok-advertising"
            min-width="1200px"
            :items="items"
            :fields="fields"
            :rows-per-page="50"
            :header-margin="145"
            notification=""
            notification-type=""
            initial-sorting="Dealer ID"
            initial-sort-direction="desc"
            :footer-titles-colspan="3"
            has-totals
            slim-cells />
    </div>
</template>

<script>
import CustomTable from '@/components/globals/CustomTable';

export default {
    components: {
        CustomTable
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            fields: [
                {
                    id: 'dealer_id',
                    header: 'Dealer ID',
                    value: 'dealer_id',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'dealer_name',
                    header: 'Dealer Name',
                    value: 'dealer_name',
                    align: 'left',
                    width: '200px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'ad_account_id',
                    header: 'Ad Account Id',
                    value: 'ad_account_id',
                    align: 'left',
                    width: '200px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'spend',
                    header: 'Spend',
                    value: 'metrics.spend.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.spend'),
                    total: true,
                    average: true,
                    format: 'currency',
                },
                {
                    id: 'impressions',
                    header: 'Impressions',
                    value: 'metrics.impressions.formatted',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.impressions'),
                    total: true,
                    average: true,
                    format: 'number',
                },
                {
                    id: 'cpm',
                    header: 'CPM',
                    value: 'metrics.cpm.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.cpm'),
                    total: true,
                    average: true,
                    format: 'currency',
                    calculated: (totals) => {
                        return totals.spend / ( totals.impressions / 1000 );
                    },
                    calculatedToolTip: {
                        title: 'CPM (Cost per 1,000 Impressions)',
                        details: [
                            {
                                item: 'spend',
                                value: null,
                            },
                            {
                                item: 'impressions',
                                value: null,
                            },
                            {
                                item: '1000',
                                value: 1000,
                            },
                        ]
                    }
                },
                {
                    id: 'clicks',
                    header: 'Clicks',
                    value: 'metrics.clicks.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.clicks'),
                    total: true,
                    average: true,
                    format: 'number',
                },
                {
                    id: 'clicks_rate',
                    header: 'CTR',
                    value: 'metrics.clicks_rate.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.ctr'),
                    total: true,
                    average: true,
                    format: 'percent',
                    calculated: (totals) => {
                        return totals.clicks / totals.impressions;
                    },
                    calculatedToolTip: {
                        title: 'CTR (Click Through Rate)',
                        details: [
                            {
                                item: null,
                                value: null,
                            },
                            {
                                item: 'clicks',
                                value: null,
                            },
                            {
                                item: 'impressions',
                                value: null,
                            },
                        ]
                    }
                },
                {
                    id: 'clicks_cost_per',
                    header: 'CPC',
                    value: 'metrics.clicks_cost_per.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.cpc'),
                    total: true,
                    average: true,
                    format: 'currency',
                    calculated: (totals) => {
                        return totals.spend / totals.clicks;
                    },
                    calculatedToolTip: {
                        title: 'CPC (Cost Per Click)',
                        details: [
                            {
                                item: null,
                                value: null,
                            },
                            {
                                item: 'spend',
                                value: null,
                            },
                            {
                                item: 'clicks',
                                value: null,
                            },
                        ]
                    }
                },
                {
                    id: 'online_consult',
                    header: 'Leads',
                    value: 'metrics.online_consult.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.web_leads'),
                    total: true,
                    average: true,
                    format: 'number',
                },
                {
                    id: 'online_consult_rate',
                    header: 'CR',
                    value: 'metrics.online_consult_rate.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.lead_rate'),
                    total: true,
                    average: true,
                    format: 'percent',
                    calculated: (totals) => {
                        return totals.online_consult / totals.clicks;
                    },
                    calculatedToolTip: {
                        title: 'CR (Lead Conversion Rate)',
                        details: [
                            {
                                item: null,
                                value: null,
                            },
                            {
                                item: 'online_consult',
                                value: null,
                            },
                            {
                                item: 'clicks',
                                value: null,
                            },
                        ]
                    }
                },
                {
                    id: 'online_consult_cost_per',
                    header: 'CPL',
                    value: 'metrics.online_consult_cost_per.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.cpl'),
                    total: true,
                    average: true,
                    format: 'currency',
                    calculated: (totals) => {
                        return totals.spend / totals.online_consult;
                    },
                    calculatedToolTip: {
                        title: 'CPL (Cost Per Lead)',
                        details: [
                            {
                                item: null,
                                value: null,
                            },
                            {
                                item: 'spend',
                                value: null,
                            },
                            {
                                item: 'online_consult',
                                value: null,
                            },
                        ]
                    }
                },
                {
                    id: 'sales',
                    header: 'Sales',
                    value: 'metrics.offline_shopping_events.formatted',
                    align: 'left',
                    width: '100px',
                    sortable: true,
                    type: 'single',
                    tooltip: this.$t('metrics.sales'),
                    total: true,
                    average: true,
                    format: 'number',
                }
            ]
        };
    }
};
</script>
