<template>
    <div
        class="styled-progress-bar"
        :class="{
            'arrows': arrows
        }"
        :style="{ height }">
        <div
            class="styled-progress-bar-line"
            :style="{
                color: derivedColor,
                backgroundColor: derivedColor,
                width: `${derivedValue * 100}%`
            }" />
    </div>
</template>

<script>
import colors from '@/helpers/colors';

export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        color: {
            default: 'blue',
            type: String
        },
        height: {
            default: '10px',
            type: String
        },
        arrows: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        derivedColor() {
            if (colors[this.color]) {
                return colors[this.color];
            }

            return this.color;
        },
        derivedValue() {
            if (this.value < 0) {
                return 0;
            }

            if (this.value > 1) {
                return 1;
            }

            return this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
.styled-progress-bar {
    width: 100%;
    position: relative;
    background-color: lighten($loblolly, 9%);

    &.arrows {
        clip-path: polygon(0 0, calc(100% - 5px) 0, 100% 50%, calc(100% - 5px) 100%, 0 100%);

        .styled-progress-bar-line {
            clip-path: polygon(0 0, calc(100% - 5px) 0, 100% 50%, calc(100% - 5px) 100%, 0 100%);
        }
    }
}

.styled-progress-bar-line {
    position: absolute;
    height: 100%;
}
</style>
