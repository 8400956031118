var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"styled-progress-bar",class:{
        'arrows': _vm.arrows
    },style:({ height: _vm.height })},[_c('div',{staticClass:"styled-progress-bar-line",style:({
            color: _vm.derivedColor,
            backgroundColor: _vm.derivedColor,
            width: `${_vm.derivedValue * 100}%`
        })})])
}
var staticRenderFns = []

export { render, staticRenderFns }