<template>
    <div
        class="confirm-dialog">
        <h3>Ready to run {{ reportName }} report?</h3>
        <p class="mb-4 mt-3">
            You are about to load {{ dataType }} data for {{ dealersTotal }} accounts.<br>
            As an option you can filter by one or more groups to minimize load time and focus on specific accounts.
        </p>
        <p v-if="estimatedTime">
            <em>Estimated time: {{ estimatedTime }}</em>
        </p>
        <div class="text-xs-center mt-4">
            <styled-button
                @click="$emit('load')">
                LOAD REPORT
            </styled-button>
        </div>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';

export default {
    components: {
        StyledButton
    },
    props: {
        reportName: {
            type: String,
            required: true
        },
        dataType: {
            type: String,
            required: true
        },
        dealersTotal: {
            type: [Number, String],
            default: 0
        },
        estimatedTime: {
            type: [Number, String],
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
.confirm-dialog {
    margin: 60px auto;
    padding: 20px;
    border: 1px solid $gray-light;
    background-color: $alabaster;
    max-width: 600px;
}
</style>

